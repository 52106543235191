<template>
    <!--设置 自动提醒训练-->
    <div class="remindtrainmodal">
        <Modal
            v-model="remindTrainModal"
            :footer-hide="true"
            class-name="vertical-center-modal remindtrain"
            :mask-closable="false"
            title="提醒训练设置"
            @on-cancel="cancel">
            <Row>
                <span>每日提醒训练时间：</span>
                <select name="" id="" v-model="completeData.scheduleHour">
                    <option :value="item.value" v-for="(item, index) in times" :key="index">{{item.label}}</option>
                </select>
                <i-switch v-model="switch1" @on-change="change" size="large"/>
            </Row>
            <div class="bth">
                <Button type="primary" size="large" @click="complete">保存</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
    import { getCookieDid } from '@js/session.sg'
    export default {
        data () {
            return {
                remindTrainModal: false,
                switch1: false,
                completeData: {
                    doctorId: getCookieDid(),
                    scheduleHour: 10,
                    enableStatus: 0
                },
                times: [
                    {
                        label: '0时',
                        value: 0
                    },
                    {
                        label: '1时',
                        value: 1
                    },
                    {
                        label: '2时',
                        value: 2
                    },
                    {
                        label: '3时',
                        value: 3
                    },
                    {
                        label: '4时',
                        value: 4
                    },
                    {
                        label: '5时',
                        value: 5
                    },
                    {
                        label: '6时',
                        value: 6
                    },
                    {
                        label: '7时',
                        value: 7
                    },
                    {
                        label: '8时',
                        value: 8
                    },
                    {
                        label: '9时',
                        value: 9
                    },
                    {
                        label: '10时',
                        value: 10
                    },
                    {
                        label: '11时',
                        value: 11
                    },
                    {
                        label: '12时',
                        value: 12
                    },
                    {
                        label: '13时',
                        value: 13
                    },
                    {
                        label: '14时',
                        value: 14
                    },
                    {
                        label: '15时',
                        value: 15
                    },
                    {
                        label: '16时',
                        value: 16
                    },
                    {
                        label: '17时',
                        value: 17
                    },
                    {
                        label: '18时',
                        value: 18
                    },
                    {
                        label: '19时',
                        value: 19
                    },
                    {
                        label: '20时',
                        value: 20
                    },
                    {
                        label: '21时',
                        value: 21
                    },
                    {
                        label: '22时',
                        value: 22
                    },
                    {
                        label: '23时',
                        value: 23
                    }
                ]
            }
        },
        methods: {
            // 获取医生选择的时间段  以及开关的状态
            // 打开弹框
            openModal () {
                // 打开弹框  先拉取 设置的时间和开启状态
                this.$http.get('btm/doctor/config/getById?doctorId=' + getCookieDid())
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            // todo
                            this.completeData = res.data;
                            if (res.data.enableStatus === 1) {
                                this.switch1 = true;
                            } else {
                                this.switch1 = false;
                            }
                            this.remindTrainModal = true;
                        } else {
                            this.$Message.error(res.msg);
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
            // 关闭弹框
            cancel () {
                this.completeData = {};
            },
            // 修改开关状态
            change (status) {
                this.switch1 = status;
            },
            // 点击完成
            complete () {
                if (this.switch1) {
                    this.completeData.enableStatus = 1
                } else {
                    this.completeData.enableStatus = 0
                }
                let data = this.completeData;
                this.$http.post('btm/doctor/config/saveOrUpdate', this.$http.adornData(data, false, 'json'), this.$http.jsonContent())
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            // todo
                            this.remindTrainModal = false;
                            this.$Message.success(res.msg);
                        } else {
                            this.$Message.error(res.msg);
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
        }
    }
</script>

<style scoped lang='scss'>
    /*remind-train-modal*/
    /*弹出框垂直居中*/
    /deep/.vertical-center-modal{
        display: flex;
        align-items: center;
        justify-content: center;
        .ivu-modal{
            top: 0;
        }
    }
    /deep/.remindtrain{
        .ivu-modal{
            width: 500px !important;
            .ivu-modal-content{
                .ivu-modal-header{
                    .ivu-modal-header-inner{
                        text-align: center;
                    }
                }
                .ivu-modal-body{
                    padding: 50px;
                    span{
                        font-size: 24px;
                        font-weight: 500;
                    }
                    select{
                        width: 20%;
                        height: 35px;
                        margin-right: 30px;
                    }
                    .bth{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 50px;
                        button{
                            width: 100px;
                        }
                    }
                }
            }
        }
    }
</style>
