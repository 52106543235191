<template>
  <div class="mainbox">
    <!-- <screenmain v-show="screenmain"></screenmain> -->
    <div class="layout">
      <Layout>
        <Header>
          <Menu mode="horizontal" theme="dark" active-name="1">
            <div class="layout-logo">
              <img src="../assets/img/topTitle.png" alt="" />
            </div>
            <div class="layout-nav">
              <MenuItem
                name="1"
                :class="isMainSelected ? 'tabSel' : ''"
                @click.native="toPage('/main')"
              >
                今日训练
              </MenuItem>
              <MenuItem
                name="2"
                :class="path === '/main/patientReport' ? 'tabSel' : ''"
                @click.native="toPage('/main/patientReport')"
              >
                测评报告
              </MenuItem>
              <MenuItem
                name="3"
                :class="path === '/main/patientrecord' ? 'tabSel' : ''"
                @click.native="toPage('/main/patientrecord')"
              >
                训练日志
              </MenuItem>
              <MenuItem
                name="4"
                :class="isBrainSelected ? 'tabSel' : ''"
                @click.native="toPage('/main/brainstatistics')"
              >
                脑力情况
              </MenuItem>
              <!-- <MenuItem
                                name="5"
                                :class="
                                    path === '/main/todaystatistics'
                                        ? 'tabSel'
                                        : ''
                                "
                                @click.native="toPage('/main/todaystatistics')"
                            >
                                今日指数
                            </MenuItem> -->
            </div>
          </Menu>
        </Header>
        <Layout style="height: calc(100vh - 190px); position: relative">
          <Layout style="height: 100%">
            <router-view ref="home" @autoLogout="autoLogout"></router-view>
          </Layout>
        </Layout>
        <!-- <Footer>
          <h1>软件发布版本号：V2.0</h1>
          <h1>软件完整版本号：V2.0.0</h1>
          <p @click="openProductModal">产品说明</p>
        </Footer> -->
      </Layout>
      <!-- <patientqr ref="child"></patientqr>
            <doctorqr ref="doctorchild"></doctorqr> -->
      <updapassmod ref="UpdaPassMod"></updapassmod>
      <!--登录弹框-->
      <!-- <loginmod ref="logModChild" @goPath="goPath"></loginmod> -->
      <!--设置自动提醒训练弹框-->
      <remindtrainmodal ref="remindtrainmodal"></remindtrainmodal>
      <div class="main-right">
        <p class="setSystem" v-if="/android/.test(this.ua)" @click="setSystem">
          <img src="../assets/img/shezhi.png" alt="" />
        </p>
        <div class="user">
          <div class="icon">
            <img :src="imagedata !== '' ? imagedata : imagedatas" alt="" />
          </div>
          <Poptip :content="patientInfo.patientName" placement="bottom">
            <span class="name">{{ handleLongName }}</span>
          </Poptip>
        </div>
        <div class="logout" @click="logoutHandler">
          <img class="icon" src="../assets/img/out.png" alt="" />
          <span>退出账号</span>
        </div>
      </div>
      <!-- 产品信息 -->
      <!-- <div class="productinfo-box">
                <span>软件发布版本号：V1.0</span>
                <span class="mid-span">软件完整版本号：V1.0.0</span>
                <span @click="openProductModal">产品说明</span>
            </div> -->
    </div>
    <Modal
      v-model="openModal"
      :mask-closable="false"
      class-name="vertical-center-modal modalHead product"
      :footer-hide="true"
      title="产品说明"
    >
      <div>
        <h1>产品名称：认知功能康复训练与评估软件</h1>
        <h1>型号规格：JSNL- KFXL- 01</h1>
        <div>
          <span>发布版本号：V2.0</span
          ><span class="span-space">序列号：202012B01</span>
        </div>
        <h1>生产日期：2020.12.18</h1>
        <h1>使用期限：10年</h1>
      </div>
      <div class="descone">
        <h1>医疗器械注册证编号：</h1>
        <h1>注册人/生产企业名称：湖南京师脑力科技有限公司</h1>
        <h1 class="info-long">
          住所/生产地址：湖南省长沙市天心区创业路159号长沙宝成建材有限公司1栋高层建材加工厂房901-4房
        </h1>
        <h1>联系方式：15901135809</h1>
      </div>
      <div>
        <h1>其他内容详见说明书</h1>
      </div>
    </Modal>
    <Modal
      v-model="isShow"
      :footer-hide="true"
      class-name="Modal1"
      :closable="false"
      :mask-closable="false"
    >
      <h1>
        确定退出训练者&nbsp;{{ this.patientInfo.patientName }}&nbsp;的账号吗？
      </h1>
      <div class="bths">
        <button @click="close">取消</button>
        <button @click="goOut">确定</button>
      </div>
    </Modal>
  </div>
</template>
<script>
// import loginmod from "../components/modal/loginMod";
// import patientqr from "../components/qr/patientQr"; // 邀请患者二维码
// import doctorqr from "../components/qr/doctorQr"; // 邀请同行二维码
// import screenmain from "./ScreenMain";
import { getCookieDname, LoginOut, getCookieAccessToken } from "@js/session.sg";
import updapassmod from "../components/modal/UpdaPassMod";
import remindtrainmodal from "../components/modal/remindTrainModal"; // 设置自动提醒训练时间弹框
export default {
  components: {
    // patientqr,
    updapassmod,
    // doctorqr,
    // loginmod,
    // screenmain,
    remindtrainmodal,
  },
  data() {
    return {
      path: "/main", // 路由地址
      screenmain: false,
      openModal: false,
      // isMainSelected: false
      ua: "",
      imagedata: "",
      imagedatas: require("../assets/img/headerIcon.png"),
      isShow: false,
    };
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.path = val.path;
      },
      // 深度观察监听
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // 这里需要把store 动态的数据放到computed里面才会同步更新视图
    token() {
      return this.$store.state.token;
    },
    patientInfo() {
      return JSON.parse(sessionStorage.getItem("patientInfo"));
    },
    isMainSelected() {
      if (
        this.path === "/main" ||
        this.path === "/main/traininfo" ||
        this.path === "/main/todaystatistics"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isBrainSelected() {
      if (
        this.path === "/main/brainstatistics" ||
        this.path === "/main/stagereport"
      ) {
        return true;
      } else {
        return false;
      }
    },
    handleLongName() {
      if (
        this.patientInfo.patientName &&
        this.patientInfo.patientName.length > 6
      ) {
        return this.patientInfo.patientName.substr(0, 5) + "...";
      }
      return this.patientInfo.patientName;
    },
  },
  methods: {
    // 点击系统设置
    setSystem() {
      if (/android/.test(this.ua)) {
        window.JSBridge.goSystemSetting();
      }
    },
    // 打开产品说明弹框
    openProductModal() {
      this.openModal = true;
    },
    // 点击首页tab
    toPage(path) {
      this.path = path;
      if (this.token === "") {
        if (path === "/main/patientpage" || path === "/main/datamgr") {
          // 打开登录弹框
          this.$refs.logModChild.openLoginMod();
        } else {
          this.$router.push(path);
        }
      } else {
        this.$router.push(path);
      }
    },
    // 点击登录回调
    goPath() {
      this.$router.replace(this.path);
    },
    // 打开邀请患者
    openPQR() {
      this.$refs.child.open();
    },
    // 打开邀请同行
    openDQR() {
      this.$refs.doctorchild.open();
    },
    // 打开自动提醒训练 弹框
    openRemindTrainModal() {
      this.$refs.remindtrainmodal.openModal();
    },
    // 刷新或者跳转获取路径
    getPath() {
      this.path = this.$route.path;
    },
    // 首页 点击跳转测评工具回调
    // changePath (val) {
    //     this.path = val
    // }
    // TODO
    logoutHandler() {
      console.log(this.$route.path);
      if (this.$route.path === "/main") {
        this.$nextTick(() => {
          this.$refs.home.addsreod();
        });
      } else {
        this.isShow = true;
      }
    },
    // 弹窗确定
    goOut() {
      console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
      this.$Modal.remove();
      this.$store.dispatch("setToken", "");
      this.$store.dispatch("setPatInfo", "");
      sessionStorage.clear();
      window.localStorage.clear();
      // clearTimeout(this.timer);
      // this.timer = null;
      // window.removeEventListener("keyup", this.resetTimer);
      // window.removeEventListener("mousemove", this.resetTimer);
      // window.removeEventListener("mousedown", this.resetTimer);
      // window.removeEventListener("mousewheel", this.resetTimer);
      this.$router.push("/login");
      this.isShow = false;
    },
    // 弹窗取消
    close() {
      this.isShow = false;
    },
    autoLogout(str) {
      // this.$store.dispatch("setToken", "");
      // this.$store.dispatch("setDocInfo", "");
      // localStorage.clear();
      // sessionStorage.clear();
      // this.path = "/login";
      // this.$router.replace("/login");
      console.log(str);
      if (str === "1") {
        // localStorage.clear();
        // sessionStorage.clear();
        // window.removeEventListener("keyup", this.resetTimer);
        // window.removeEventListener("mousemove", this.resetTimer);
        // window.removeEventListener("mousedown", this.resetTimer);
        // window.removeEventListener("mousewheel", this.resetTimer);
        // window.removeEventListener("touch", this.resetTimer);
        // window.removeEventListener("touchmove", this.resetTimer);
        // clearTimeout(this.timer);
        // this.timer = null;
        // this.$router.replace("/login");
        this.isShow = true;
      } else if (str === "2") {
        this.$store.dispatch("setToken", "");
        this.$store.dispatch("setPatInfo", "");
        localStorage.clear();
        sessionStorage.clear();
        this.$router.replace("/login");
      } else {
        this.$store.dispatch("setToken", "");
        this.$store.dispatch("setPatInfo", "");
        // window.removeEventListener("keyup", this.resetTimer);
        // window.removeEventListener("mousemove", this.resetTimer);
        // window.removeEventListener("mousedown", this.resetTimer);
        // window.removeEventListener("mousewheel", this.resetTimer);
        // window.removeEventListener("touch", this.resetTimer);
        // window.removeEventListener("touchmove", this.resetTimer);
        // clearTimeout(this.timer);
        // this.timer = null;
        this.$popup();
      }
    },
    // resetTimer() {
    //   if (getCookieAccessToken()) {
    //     // console.log('进入了if')
    //     if (this.timer !== null) {
    //       // console.log('清空了当前的timer')
    //       clearTimeout(this.timer);
    //     }
    //     this.timer = setTimeout(() => {
    //       // console.log('10s后调用了autoLogout')
    //       this.autoLogout();
    //     }, 60000 * 15);
    //     // console.log('this.timer', this.timer)
    //   }
    // },
  },
  mounted() {
    this.ua = navigator.userAgent.toLowerCase();
    this.getPath();
    // // 进入首页就开始执行倒计时
    // this.resetTimer();
    // // 监听键盘按下事件
    // window.addEventListener("keyup", this.resetTimer);
    // // 监听 鼠标移动事件
    // window.addEventListener("mousemove", this.resetTimer);
    // // 监听鼠标按下事件
    // window.addEventListener("mousedown", this.resetTimer);
    // // 监听鼠标滚动
    // window.addEventListener("mousewheel", this.resetTimer);
    // // 监听触摸事件
    // window.addEventListener("touch", this.resetTimer);
    // // 监听触摸移动事件
    // window.addEventListener("touchmove", this.resetTimer);
    let data = {
      patientId: this.patientInfo.patientId,
      doctorId: sessionStorage.getItem("doctorId"),
    };
    this.$http
      .post(
        "patient/plan/info/getTodayTrainInfo",
        this.$http.adornData(data, false, "json"),
        this.$http.jsonContent()
      )
      .then((res) => {
        if (res.code === 1) {
          console.log(res.data);
          this.imagedata = res.data.patientAvatar;
          console.log(this.imagedata);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style scoped lang="scss">
@import "@css/modalHead.scss";
.mainbox {
  height: 100%;
}
.layout {
  position: relative;
  /*border: 1px solid #d7dde4;*/
  background: #f5f7f9;
  position: relative;
  height: 100%;
  // border-radius: 4px;
  overflow: hidden;
  .main-right {
    position: absolute;
    top: 20px;
    right: 78px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .user {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      margin-right: 32px;
      .icon {
        width: 34px;
        height: 34px;
        margin-right: 9px;
        img {
          width: 34px;
          height: 34px;
          border-radius: 50%;
        }
      }
      .name {
        font-size: 21px;
        font-weight: 300;
        color: #ffffff;
      }
      /deep/ .ivu-poptip-rel {
        margin-top: 5px;
      }
      /deep/ .ivu-poptip-content {
        font-size: 24px;
        font-weight: 500;
      }
    }
    .setSystem {
      // font-size: 21px;
      // font-weight: 300;
      // color: #ffffff;
      margin-right: 32px;
      cursor: pointer;
      img {
        width: 36px;
        height: 36px;
        border-radius: 100%;
      }
    }
    .logout {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 40px;
      background: #fff;
      border-radius: 20px;
      cursor: pointer;
      opacity: 0.9;
      .icon {
        width: 20px;
        height: 20px;
        color: #4174f7;
      }
      span {
        margin-left: 9px;
        font-size: 20px;
        font-weight: 400;
        color: #4174f7;
        line-height: 20px;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
      }
    }
  }
}
/deep/.ivu-menu-primary {
  background: rgba(65, 116, 247, 1);
}
/deep/.ivu-layout-header {
  height: 80px;
  background: #4174f7;
  padding: 0 80px;
  line-height: 120px;
}
/deep/.ivu-menu-dark {
  background: #4174f7;
  display: flex;
  align-items: center;
}
/deep/.ivu-menu-horizontal {
  height: 80px;
  line-height: 80px;
}
// /deep/.layout-logo {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     width: 411px;
//     height: 60px;
//     margin-right: 122px;
//     background: chartreuse;
// }
// /deep/.layout-logo .left{
//     width:50px;
//     height:50px;
//     margin-right: 9px;
// }
// /deep/.layout-logo .left img{
//     width:50px;
//     height:50px;
// }
// /deep/.layout-logo .right{
//     display: flex;
//     flex-direction: column;
// }
// /deep/.layout-logo .right h1{
//     height:28px;
//     font-size:28px;
//     line-height: 28px;
//     font-weight:500;
//     color:rgba(255,255,255,1);
// }
// /deep/.layout-logo .right p{
//     margin-top: 5px;
//     height:10px;
//     line-height: 10px;
//     font-size:10px;
//     font-weight:300;
//     color:rgba(255,255,255,1);
//     white-space: nowrap;
// }
/deep/.layout-nav {
  flex: 1;
  display: flex;
  /*justify-content: space-between;*/
  .tabSel {
    position: relative;
    font-size: 24px !important;
    color: #ffffff !important;
    font-weight: bolder !important;
    opacity: 1 !important;
    // border-bottom: 2px solid rgba(255, 255, 255, 1) !important;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 3px;
      background: #fff;
    }
  }
}
/deep/.layout-nav {
  height: 98%;
}
/deep/.ivu-menu-primary {
  background: rgba(65, 116, 247, 1);
}
/deep/.layout-nav .ivu-menu-item {
  font-size: 24px;
  color: #ffffff;
  padding: 0;
  margin-right: 95px;
  font-weight: 400;
  white-space: nowrap;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
}
/deep/
  .ivu-menu-horizontal
  .ivu-menu-submenu
  .ivu-select-dropdown
  .ivu-menu-item {
  font-size: 24px !important;
  color: #ffffff !important;
  line-height: 24px !important;
}
// /deep/.ivu-menu-submenu{
//     font-size:24px;
//     font-weight:300;
//     color:rgba(255,255,255,1) !important;
//     .ivu-icon{
//         font-size: 30px;
//     }
// }
/deep/.layout-footer-center {
  text-align: center;
}

// logo样式
/deep/ .ivu-layout {
  height: 100%;
}
/deep/.layout-logo {
  display: flex;
  align-items: center;
  height: 100%;
  line-height: 100%;
  margin-right: 128px;
  img {
    width: 367px;
    height: 54px;
  }
}
/deep/.layout-logo .left {
  width: 50px;
  height: 50px;
  margin-right: 9px;
}
/deep/.layout-logo .left img {
  width: 50px;
  height: 50px;
}
/deep/.layout-logo .right {
  display: flex;
  flex-direction: column;
}
/deep/.layout-logo .right h1 {
  height: 28px;
  font-size: 28px;
  line-height: 28px;
  font-weight: 500;
  color: rgba(255, 255, 255, 1);
}
/deep/.layout-logo .right p {
  margin-top: 5px;
  height: 10px;
  line-height: 10px;
  font-size: 10px;
  font-weight: 300;
  color: rgba(255, 255, 255, 1);
  white-space: nowrap;
}
// 产品信息绝对定位在main的底部
.productinfo-box {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  // background: rgba(229, 242, 252, 1);
  background: #e9f2f8;
  span {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    &:last-child {
      cursor: pointer;
      color: #4174f7;
    }
  }
  .mid-span {
    margin: 0 20px;
  }
}
/*底部样式*/
/deep/.ivu-layout-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: #e8f1fb;
  h1 {
    font-size: 14px;
    font-weight: 400;
    color: #666666 !important;
    &:nth-child(2) {
      margin: 0 20px;
    }
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: #4174f7;
    cursor: pointer;
  }
}
/*产品说明弹框*/
/deep/.product {
  .ivu-modal {
    width: 1000px !important;
    .ivu-modal-body {
      padding: 40px 60px;
      h1,
      div {
        font-size: 20px !important;
        font-weight: 400 !important;
        color: #666666 !important;
        margin-top: 15px;
      }
      .descone {
        margin: 30px 0;
      }
    }
    .span-space {
      margin-left: 10px;
    }
    // .info-long {
    //     line-height: 25px;
    // }
  }
}
/deep/.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}
/deep/.Modal1 {
  display: flex;
  align-items: center;
  .ivu-modal {
    width: 600px !important;
    top: 0 !important;
    .ivu-modal-content {
      .ivu-modal-body {
        padding: 56px !important;
        h1 {
          font-size: 24px;
          font-weight: bolder;
          color: #333333;
        }
        .bths {
          margin-top: 58px;
          display: flex;
          justify-content: center;
          button {
            min-width: 200px;
            padding: 15px 0;
            box-sizing: border-box;
            border: 1px solid #4174f7;
            border-radius: 9px;
            background: #fff;
            font-size: 24px;
            font-weight: bolder;
            color: #4174f7;
            cursor: pointer;
          }
          button:nth-child(1) {
            margin-right: 32px;
          }
          button:nth-child(2) {
            background: #4174f7;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
