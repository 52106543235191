<template>
  <div class="container" ref="container">
    <div
      class="haveData"
      v-if="
        todayTrainInfo.planList.length !== 0 &&
        showTrue === true &&
        trainDetails.trainList.length !== 0
      "
    >
      <div class="top">
        <div class="left">
          <div class="center_left_top_left">
            <img :src="imagedata !== '' ? imagedata : imagedatas" alt="" />
            <Poptip
              trigger="hover"
              :content="patientInfo.patientName"
              placement="bottom"
            >
              <span class="val">{{ handleLongName || "--" }}</span>
            </Poptip>
            <span class="name gender">{{ todayTrainInfo.patientSexStr }}</span>
            <span class="name age">{{ todayTrainInfo.patientAge }}岁</span>
          </div>
          <h1>今日脑力值：{{ todayTrainInfo.todayBrainScore || "--" }}</h1>
          <div class="center_left_top_right">
            <span
              >最近训练日期：{{
                trainDetails.lastTrainDate ? trainDetails.lastTrainDate : "--"
              }}</span
            >
          </div>
        </div>
        <!-- <div class="right">
          <p>我的训练方案</p>
          <p v-if="!isHavePlanList">--</p>
          <p v-else>
            <span
              v-for="(item, index) in todayTrainInfo.planList"
              :key="index"
              @click="clickItem(index, item.planName)"
            >
              {{ item.planName }}
            </span>
          </p>
        </div> -->
        <div class="train-plan">
          <div class="info-box">
            <div class="icon"></div>
            <span class="name">我的训练方案</span>
            <span class="empty-val" v-if="!isHavePlanList">--</span>
          </div>
          <div class="plan-box" v-if="isNamePrepared && isHavePlanList">
            <div class="current" @click="clickMore">
              <div class="content">
                {{ todayTrainInfo.planList[currentPlanIndex].planName }}
                <img src="../assets/img/jiantou.png" alt="" v-if="isMore" />
              </div>
              <ul class="plan-list" v-if="isMore && isMoreListShow">
                <li
                  v-for="(item, index) in todayTrainInfo.planList"
                  :key="index"
                  @click="clickItem(index)"
                >
                  <span
                    :class="Number(currentPlanIndex) === index ? 'active' : ''"
                    v-if="item"
                    >{{ item.planName }}</span
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="center">
        <div class="center_left">
          <div class="center_left_top">
            <div class="center_left_top_left">
              <span v-if="isNamePrepared && isHavePlanList">
                {{ todayTrainInfo.planList[currentPlanIndex].planName }}
              </span>
              <span
                >第{{ trainDetails.planTotalDays - trainDetails.remindDays }}天
                / 共{{ trainDetails.planTotalDays }}天</span
              >
            </div>
          </div>
          <div class="center_left_content">
            <h1>训练进度</h1>
            <div class="center_left_content_courss">
              <p>
                <span :style="{ width: pross + '%' }"
                  ><img src="../assets/img/xiaoqi.png" alt="" />
                  <h1>第{{ numb }}天</h1></span
                >
              </p>
              共{{ trainDetails.planTotalDays }}天
            </div>
            <div class="center_left_content_img"></div>
          </div>
        </div>
        <div class="center_right">
          <div class="center_right_top">
            <div class="center_right_top_left">
              <span> 今日完成度 </span>
              <span>
                {{
                  trainDetails.todayFinishProcess === 0
                    ? "未开始"
                    : trainDetails.todayFinishProcess + "%"
                }}
              </span>
            </div>
            <div
              class="center_right_top_right"
              v-if="trainDetails.todayFinishProcess === 100"
            >
              <span>您已完成今日训练，真棒！</span>
            </div>
          </div>
          <div
            class="center_right_content"
            v-if="
              trainDetails.todayFinishProcess !== 0 &&
              trainDetails.trainList[0].trainCount !== ''
            "
          >
            <p>
              <span
                :style="{
                  width:
                    trainDetails.trainList[0].trainCount === 1 ? '100%' : '0%',
                }"
              ></span>
            </p>
            <img
              :src="
                trainDetails.trainList[0].trainCount === 1
                  ? selectTrue
                  : noselect
              "
              alt=""
            />
            <p>
              <span
                :style="{
                  width:
                    trainDetails.trainList[1].trainCount === 0
                      ? '0%'
                      : trainDetails.trainList[1].trainCount === 1
                      ? '33%'
                      : trainDetails.trainList[1].trainCount === 2
                      ? '66%'
                      : trainDetails.trainList[1].trainCount === 3
                      ? '100%'
                      : '0%',
                  borderRadius:
                    trainDetails.trainList[1].trainCount === 3
                      ? '0'
                      : '0 5px 5px 0',
                }"
              ></span>
            </p>
            <img
              :src="
                trainDetails.trainList[1].trainCount === 3
                  ? selectTrue
                  : noselect
              "
              alt=""
            />
            <p>
              <span
                :style="{
                  width:
                    trainDetails.trainList[2].trainCount === 0
                      ? '0%'
                      : trainDetails.trainList[2].trainCount === 1
                      ? '33%'
                      : trainDetails.trainList[2].trainCount === 2
                      ? '66%'
                      : trainDetails.trainList[2].trainCount === 3
                      ? '100%'
                      : '0%',
                  borderRadius:
                    trainDetails.trainList[2].trainCount === 3
                      ? '0'
                      : '0 5px 5px 0',
                }"
              ></span>
            </p>
            <img
              :src="
                trainDetails.trainList[2].trainCount === 3
                  ? selectTrue
                  : noselect
              "
              alt=""
            />
            <p>
              <span
                :style="{
                  width:
                    trainDetails.trainList[3].trainCount === 0
                      ? '0%'
                      : trainDetails.trainList[3].trainCount === 1
                      ? '33%'
                      : trainDetails.trainList[3].trainCount === 2
                      ? '66%'
                      : trainDetails.trainList[3].trainCount === 3
                      ? '100%'
                      : '0%',
                  borderRadius:
                    trainDetails.trainList[3].trainCount === 3
                      ? '0'
                      : '0 5px 5px 0',
                }"
              ></span>
            </p>
            <img
              :src="
                trainDetails.trainList[3].trainCount === 3
                  ? selectTrue
                  : noselect
              "
              alt=""
            />
          </div>
          <div
            class="center_right_content1"
            v-if="trainDetails.todayFinishProcess === 0"
          >
            <p>今日专属训练还未进行，别忘记呦！</p>
          </div>
          <div
            class="center_right_bottom"
            v-if="
              trainDetails.todayFinishProcess !== 0 &&
              trainDetails.trainList[0].trainCount !== ''
            "
          >
            <div>
              <p>预备课({{ trainDetails.trainList[0].trainCount }}/1)</p>
              <p
                :class="
                  trainDetails.trainList[0].trainCount === 1 ? 'colors' : ''
                "
              >
                {{
                  trainDetails.trainList[0].trainCount === 1
                    ? "已完成"
                    : "未完成"
                }}
              </p>
            </div>
            <div>
              <p>第一节课({{ trainDetails.trainList[1].trainCount }}/3)</p>
              <p
                :class="
                  trainDetails.trainList[1].trainCount === 3 ? 'colors' : ''
                "
              >
                {{
                  trainDetails.trainList[1].trainCount === 3
                    ? "已完成"
                    : "未完成"
                }}
              </p>
            </div>
            <div>
              <p>第二节课({{ trainDetails.trainList[2].trainCount }}/3)</p>
              <p
                :class="
                  trainDetails.trainList[2].trainCount === 3 ? 'colors' : ''
                "
              >
                {{
                  trainDetails.trainList[2].trainCount === 3
                    ? "已完成"
                    : "未完成"
                }}
              </p>
            </div>
            <div>
              <p>第三节课({{ trainDetails.trainList[3].trainCount }}/3)</p>
              <p
                :class="
                  trainDetails.trainList[3].trainCount === 3 ? 'colors' : ''
                "
              >
                {{
                  trainDetails.trainList[3].trainCount === 3
                    ? "已完成"
                    : "未完成"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="bottom_top">
          <p>今日为您安排以下训练</p>
          <p>提升您的：{{ explain }}</p>
        </div>
        <div class="bottom_content">
          <div
            class="bottom_content_bigbox"
            v-for="(item, index) in trainDetails.trainList"
            :key="index"
          >
            <div class="bottom_content_box" @click="clickLesson(item, index)">
              <span> {{ item.trainInfoDesc }}（{{ item.trainCount }}次）</span>
              <div>
                <img :src="item.trainTypeIcon" alt="" />
                <p>
                  {{
                    index === 0
                      ? item.trainTypeName
                      : item.trainTypeName + "训练"
                  }}
                </p>
              </div>
            </div>
            <img class="zhixiang" src="../assets/img/zhixiang.png" alt="" />
          </div>
        </div>
        <div class="btn">
          <span
            :class="this.trainDetails.trainBtnStatus === 2 ? 'textShow' : ''"
            @click="startTrain"
            ref="buton"
            >{{ btnText }}</span
          >
        </div>
      </div>
      <!-- <div class="middle">
        <div class="chart-box">
          <lineCharts></lineCharts>
        </div>
        <div class="icon-box">
          <div class="icon-1">
            <div class="icon c1"></div>
            <span>初级</span>
          </div>
          <div class="icon-1">
            <div class="icon c2"></div>
            <span>中级</span>
          </div>
          <div class="icon-1">
            <div class="icon c3"></div>
            <span>高级</span>
          </div>
        </div>
      </div> -->
      <Modal
        v-model="modal1"
        :closable="false"
        class-name="modal1"
        :footer-hide="true"
        :mask-closable="false"
      >
        <div class="patientName" v-if="dataList.length !== 0">
          今日“{{ dataList[0].planName }}”还未完成，是否前往训练？
        </div>
        <div class="bth">
          <button class="but1" @click="closes(exit)">跳过</button>
          <button class="but2" @click="goStart()">去训练</button>
        </div>
      </Modal>
      <Modal
        v-model="modal2"
        title="训练前须知"
        class-name="modal2"
        :footer-hide="true"
        :mask-closable="false"
      >
        <div>
          据专家研究表明，认知训练单次时长在<span>20~30分钟</span>左右，且每周训练<span>不少于3次</span>，坚持训练<span>1个月</span>才会有所成效。请您在开始本次训练之前确认以下事项：
        </div>
        <div>
          1.请您在自身状态良好时进行训练，若身体不适，如头晕、阵痛、乏力等，请在身体好转后再训练。
        </div>
        <div>
          2.请您在光线明亮且相对安静的环境下进行训练，若光线较暗，请打开灯再开始训练。
        </div>
        <div>
          3.请您在训练前安排好私人问题，如吃饭、喝水、去洗水间等，尽量避免造成训练中断。
        </div>
        <div class="bth">
          <button class="but1" @click="closesModal2()">
            准备好了，开始训练
          </button>
        </div>
      </Modal>
    </div>
    <div
      class="nohaveData"
      v-if="todayTrainInfo.planList.length === 0 && showTrue === false"
    >
      <div class="top">
        <div class="top_up">
          <img src="../assets/img/headerIcon.png" alt="" />
          <Poptip
            trigger="hover"
            :content="patientInfo.patientName"
            placement="bottom"
          >
            <span class="val">{{ handleLongName || "--" }}</span>
          </Poptip>
          <span class="name gender">{{ todayTrainInfo.patientSexStr }}</span>
          <span class="name age">{{ todayTrainInfo.patientAge }}岁</span>
        </div>
        <div class="top_content">
          <div class="top_content_box">
            <img src="../assets/img/noplan.png" alt="" />
            <p>您没有训练方案，请找您的医生为您制定方案</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <tabarhead></tabarhead> -->
    <!-- <div class="card">
      <Spin fix v-if="spinShow"></Spin>
      <div class="head"> -->
    <!-- <div class="head-center"> -->
    <!-- <div class="patient">
          <div class="info-box">
            <div class="icon"></div>
            <span class="name short-name">就诊人：</span>
            <Poptip
              trigger="hover"
              :content="patientInfo.patientName"
              placement="bottom"
            >
              <span class="val">{{ handleLongName || "--" }}</span>
            </Poptip>
            <span class="name gender">{{ todayTrainInfo.patientSexStr }}</span>
            <span class="name age">{{ todayTrainInfo.patientAge }}岁</span> -->
    <!-- 显示全名的气泡 hover显示  绝对定位 -->
    <!-- <div class="long-name">{{todayTrainInfo.patientName}}</div> -->
    <!-- </div>
        </div>
        <div class="brain-rate">
          <div class="info-box">
            <div class="icon"></div>
            <span class="name">今日脑力指数：</span>
            <span class="val"
              >{{ todayTrainInfo.todayBrainScore || "--" }}
            </span> -->
    <!-- <span class="val">10000</span> -->
    <!-- <div class="icon-line" v-if="todayTrainInfo.todayBrainScore"></div>
            <span class="all" v-if="todayTrainInfo.todayBrainScore">10000</span>
          </div>
        </div>
        <div class="train-date">
          <div class="info-box">
            <div class="icon"></div>
            <span class="name">最近训练日期：</span>
            <span class="val">{{
              trainDetails.lastTrainDate ? trainDetails.lastTrainDate : "--"
            }}</span>
          </div>
        </div>
        <div class="train-plan">
          <div class="info-box">
            <div class="icon"></div>
            <span class="name">您的训练方案：</span>
            <span class="empty-val" v-if="!isHavePlanList">--</span>
          </div>
          <div class="plan-box" v-if="isNamePrepared && isHavePlanList">
            <div class="current">
              {{ todayTrainInfo.planList[currentPlanIndex].planName }}
            </div>
          </div>
          <div></div>
          <div class="more" @click="clickMore" v-if="isMore">
            <span class="word">更多</span> -->
    <!-- <i class="icon icon-blue" v-if="!isMore"></i>
                        <i class="icon icon-white" v-if="isMore"></i> -->
    <!-- <span v-if="isMoreListShow" class="icon-close"
                            >关闭</span
                        > -->
    <!-- </div>
          <ul class="plan-list" v-if="isMore && isMoreListShow">
            <li
              v-for="(item, index) in todayTrainInfo.planList"
              :key="index"
              @click="clickItem(index)"
            >
              <img
                src="@/assets/img/patientend/checked.png"
                alt=""
                v-if="Number(currentPlanIndex) === index"
              />
              <img src="@/assets/img/patientend/unchecked.png" alt="" v-else />
              <span
                :class="Number(currentPlanIndex) === index ? 'active' : ''"
                v-if="item"
                >{{ item.planName }}</span
              >
            </li>
          </ul>
        </div> -->
    <!-- </div> -->
    <!-- </div>
      <div class="middle" v-if="isHavePlanList">
        <div class="plan-count">
          <div class="all">
            <i class="spot"></i>
            <span class="count">方案次数:</span>
            <span class="num">{{ trainDetails.planTotalDays }}次</span>
          </div>
          <div class="left">
            <i class="spot"></i>
            <span class="count">剩余次数:</span>
            <span class="num">{{ trainDetails.remindDays }}次</span>
          </div>
        </div> -->
    <!-- 后端应该返回用br换行的字符串 -->
    <!-- <div class="desc" v-html="trainDetails.planTrainDesc"></div>
        <div class="diagram">
          <div class="chart-box">
            <pie-charts
              :currentPercent="trainDetails.todayFinishProcess"
              v-if="ifChartShow"
            ></pie-charts>
            <div class="ab-percent">
              <span class="num">{{ trainDetails.todayFinishProcess }}</span>
              <span class="percent">%</span>
            </div>
          </div>
          <span>今日完成度</span>
        </div>
      </div> -->
    <!-- 预备课第*节课+开始训练按键 -->
    <!-- <div class="bottoxm-box" v-if="isHavePlanList">
        <div class="bottom">
          <div class="lesson-list">
            <div
              class="lesson"
              v-for="(item, index) in trainDetails.trainList"
              :key="index"
              @touchstart="clickLesson(item, index)"
            >
              <div class="hd">
                <span>{{ item.trainInfoDesc }}（{{ item.trainCount }}次）</span>
              </div>
              <div class="bt">
                <div class="pic">
                  <img :src="item.trainTypeIcon" alt="" />
                </div>
                <p>{{ item.trainTypeName }}</p>
              </div>
            </div>
          </div>
          <div class="btn-box">
            <div @click="startTrain" class="clickuse" ref="startTrainRef">
              <img src="@/assets/img/patientend/newbtn.png" alt="" />
              <span>{{ btnText }}</span>
            </div>
          </div>
        </div>
      </div> -->
    <!-- planList为空时显示 -->
    <!-- <div class="empty-show" v-if="!isHavePlanList">
        <div class="title">您没有进行中的方案</div>
        <span class="tip">请找您的医生分配方案</span>
        <img src="@/assets/img/patientend/empty-box.png" alt="" />
      </div>
    </div> -->
  </div>
</template>

<script>
// import tabarhead from "@c/common/tabarHead";
// import pieCharts from "@v/patientend/chartItems/home/pieCharts";
// import trainVideo from "@v/patientend/items/trainVideo";
import { Debounce } from "@js/debounce.js";
export default {
  name: "Home",
  data() {
    return {
      // browserInfo: "", // 浏览器信息
      spinShow: true,
      // btnText: '开始训练',
      ifChartShow: true,
      currentPlanIndex: 0,
      isMoreListShow: false,
      patientInfo: "",
      todayTrainInfo: {
        patientName: "",
        patientSexStr: "",
        patientAge: "",
        lastTrainDate: "",
        planList: [],
        todayBrainScore: "",
      },
      trainDetails: {
        patientPlanId: "",
        planTotalDays: "",
        remindDays: "",
        planTrainDesc: "",
        todayFinishProcess: "",
        trainList: [{ trainCount: "" }],
      },
      pross: "", // 当前天数进度条
      numb: "", // 当前天数
      noselect: require("../assets/img/noselect.png"),
      selectTrue: require("../assets/img/selectTrue.png"),
      showTrue: Boolean,
      dataList: [{ planName: "" }], // 存储未完成的训练方案
      modal1: false, // 弹窗
      timeData: {}, // 存储单次训练点击时数据的存储
      exit: 1, // 判断是点击的退出按钮 还是训练
      explain: [], // 提升的文字描述
      imagedata: "",
      imagedatas: require("../assets/img/headerIcon.png"),
      modal2: false,
    };
  },
  components: {
    // tabarhead,
    // pieCharts,
    // trainVideo // 视频组
    // lineCharts,
  },

  computed: {
    isMore() {
      if (this.todayTrainInfo) {
        if (this.todayTrainInfo.planList.length > 1) {
          return true;
        }
      }
      return false;
    },
    // 解决数组还未赋值就取值导致的undefined报错
    isNamePrepared() {
      // let name = todayTrainInfo.planList[currentPlanIndex].planName
      if (this.todayTrainInfo.planList[this.currentPlanIndex] === undefined) {
        return false;
      }
      return true;
    },
    // 计算btn文本
    btnText() {
      // trainBtnStatus=1
      if (this.trainDetails.trainBtnStatus === 1) {
        return "继续训练";
      }
      // trainBtnStatus=2
      // 如果是再练一遍的话需要加一个参数againTrainFlg
      if (this.trainDetails.trainBtnStatus === 2) {
        return "今日训练已经完成，点击课节可进行加练";
      }
      // 默认情况下trainBtnStatus=0
      return "开始训练";
    },
    // 再练一遍标识符 1-再练一遍
    againTrainFlg() {
      if (this.trainDetails.trainBtnStatus === 2) {
        return 1;
      }
      return 0;
    },
    // 如果当前没有项目，显示空白图片
    isHavePlanList() {
      if (this.todayTrainInfo.planList.length) {
        return true;
      }
      return false;
    },
    // 处理长名字
    handleLongName() {
      if (this.todayTrainInfo.patientName.length > 6) {
        return this.todayTrainInfo.patientName.substr(0, 3) + "...";
      }
      return this.todayTrainInfo.patientName;
    },
    // 开始训练/继续训练DOM对象
    startTainDom() {
      return this.$ref.startTrainRef;
    },
  },
  watch: {
    // 再次点击（包含空白区域） 关闭列表框
    isMoreListShow: {
      handler: function (val, oldVal) {
        const that = this;
        const fn1 = function (e) {
          console.log(e.target);
          that.isMoreListShow = false;
          that.$refs.container.removeEventListener("click", fn1);
        };
        if (val) {
          setTimeout(() => {
            this.$refs.container.addEventListener("click", fn1);
          }, 500);
        }
      },
    },
  },
  created() {
    this.patientInfo = this.$store.state.patientInfo;
    this.getInitData();
  },
  mounted() {
    // let data;
    // this.$http
    //   .post(
    //     "patient/train/today",
    //     this.$http.adornData(data, false, "json"),
    //     this.$http.jsonContent()
    //   )
    //   .then((res) => {
    //     console.log(res);
    //     if (res.code === 1) {
    //       // todo
    //       // data = 1 时今日已训练 0时今日未训练
    //       if (res.data === 0) {
    //         this.modal2 = true;
    //       }
    //     } else {
    //       this.$Message.error(res.msg);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    setTimeout(() => {
      this.spinShow = false;
    }, 300);
  },

  methods: {
    async getInitData() {
      if (
        this.$route.query.currentPlanIndex === "undefined" ||
        this.$route.query.currentPlanIndex === undefined
      ) {
        this.currentPlanIndex = 0;
      } else {
        this.currentPlanIndex = this.$route.query.currentPlanIndex;
      }
      let res;
      let data = {
        patientId: this.patientInfo.patientId,
        doctorId: sessionStorage.getItem("doctorId"),
      };
      try {
        res = await this.$http.post(
          "patient/plan/info/getTodayTrainInfo",
          this.$http.adornData(data, false, "json"),
          this.$http.jsonContent()
        );

        if (res.code === 1) {
          this.todayTrainInfo = res.data;
          if (res.data.planList.length !== 0) {
            this.showTrue = true;
          } else {
            this.showTrue = false;
          }
          this.imagedata = res.data.patientAvatar;
          console.log("1.getTodayTrainInfo", res);
          // 调用第二个接口获取训练信息
          // this.getTodayTrainDetailInfo(
          //   this.patientInfo.patientId,
          //   res.data.planList[0].patientPlanId
          // );
          this.getTodayTrainDetailInfo(
            this.patientInfo.patientId,
            res.data.planList[this.currentPlanIndex].patientPlanId
          );
        } else {
          this.$Message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getTodayTrainDetailInfo(patientId, patientPlanId) {
      let res;
      try {
        res = await this.$http.get(
          `patient/plan/info/getTodayTrainDetailInfo?patientId=${patientId}&patientPlanId=${patientPlanId}`
        );
        if (res.code === 1) {
          this.explain = [];
          this.trainDetails = res.data;
          this.pross = parseInt(
            ((res.data.planTotalDays - res.data.remindDays) /
              res.data.planTotalDays) *
              100
          );
          this.numb = parseInt(res.data.planTotalDays - res.data.remindDays);
          res.data.trainList.forEach((element, index) => {
            if (index !== 0) {
              this.explain.push(element.trainTypeName);
            }
          });
          console.log(this.explain);
          this.explain = Array.from(new Set(this.explain));
          this.explain = this.explain.join("、");
          console.log(this.pross);
          console.log("2.getTodayTrainDetailInfo", this.trainDetails);
          this.modal1 = false;
        } else {
          this.$Message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 点击展开plan list
    clickMore() {
      if (!this.isMore) {
        return;
      }
      if (!this.isMoreListShow) {
        this.isMoreListShow = true;
      }
      // this.isMoreListShow = !this.isMoreListShow;
    },
    // 点击plan list中具体的项 切换当前的plan
    clickItem(val, name) {
      this.currentPlanIndex = val;
      console.log(this.todayTrainInfo.planList[val].patientPlanId);
      this.getTodayTrainDetailInfo(
        this.patientInfo.patientId,
        this.todayTrainInfo.planList[val].patientPlanId
      );
      // setTimeout(() => {
      //     this.isMoreListShow = false;
      // }, 200);
    },
    // 根据后台状态判断是否允许点击
    clickLesson: Debounce(function (item, index) {
      this.dataList = [];
      // 单个训练是否可以打开 0-不可以 1-可以 TODO
      // 判断！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！！
      if (!item.canOpenFlag) {
        //
        return this.$Message.error("今日训练未完成，不可以单独训练");
      } else {
        console.log(item);
        // /**
        //  *方案完成状态(0:未开始训练 1:进行中 2:已完成)
        //  *private Integer finishStatus;
        //  */
        // 最新 今日训已完成：0.未完成 1.已完成  todayTrainCompleted
        this.todayTrainInfo.planList.forEach((element) => {
          if (element.todayTrainCompleted !== 1) {
            this.dataList.push(element);
          }
        });
        console.log(this.dataList);
        // 如果没有数据 就继续往下进行
        if (this.dataList.length === 0) {
          // const element = document.getElementsByTagName("body")[0];
          // element.requestFullscreen(); // todo
          this.$router.push({
            path: "/main/traininfo",
            query: {
              patientPlanId:
                this.todayTrainInfo.planList[this.currentPlanIndex]
                  .patientPlanId,
              schedDtlId: item.schedDtlId,
              canOpenFlag: item.canOpenFlag,
              currentPlanIndex: this.currentPlanIndex,
              imagedata: this.imagedata,
            },
          });
        } else {
          // 存储 点击的数据，用于弹窗取消时的数据进行
          this.timeData = item;
          console.log(this.timeData);
          this.$nextTick(() => {
            this.modal1 = true;
            this.exit = 1;
          });
          // 如果有数据弹窗提示
          // 打开弹窗提示用户完成训练后是否切换方案
        }
        // this.$router.push({path: '/main/traininfo', query: {patientPlanId: patientPlanId }})
      }
      // return console.log('点击了单个项目', item)
    }, 1500),
    // 弹窗取消
    closes(el) {
      this.modal1 = false;
      if (el === 1) {
        this.$router.push({
          path: "/main/traininfo",
          query: {
            patientPlanId:
              this.todayTrainInfo.planList[this.currentPlanIndex].patientPlanId,
            schedDtlId: this.timeData.schedDtlId,
            canOpenFlag: this.timeData.canOpenFlag,
            currentPlanIndex: this.currentPlanIndex,
            imagedata: this.imagedata,
          },
        });
      } else if (el === 2) {
        this.$emit("autoLogout", "2");
        // window.removeEventListener("keyup", this.resetTimer);
        //     window.removeEventListener("mousemove", this.resetTimer);
        //     window.removeEventListener("mousedown", this.resetTimer);
        //     window.removeEventListener("mousewheel", this.resetTimer);
        // this.$router.push("/login");
      }
    },
    addsreod() {
      this.dataList = [];
      this.todayTrainInfo.planList.forEach((element) => {
        if (element.todayTrainCompleted !== 1) {
          this.dataList.push(element);
        }
      });
      console.log(this.dataList);
      if (this.dataList.length === 0) {
        // this.$Modal.confirm({
        //   content:
        //     "<p>确定退出训练者" +
        //     this.patientInfo.patientName +
        //     "的账号吗？</p>",
        //   loading: true,
        //   onOk: () => {
        //     this.$Modal.remove();
        this.$emit("autoLogout", "1");
        //   },
        // });
      } else {
        this.$nextTick(() => {
          this.modal1 = true;
          this.exit = 2;
        });
      }
    },
    // 弹窗 去训练
    goStart() {
      this.getTodayTrainDetailInfo(
        this.patientInfo.patientId,
        this.dataList[0].patientPlanId
      );
      this.todayTrainInfo.planList.forEach((element, index) => {
        if (element.patientPlanId === this.dataList[0].patientPlanId) {
          this.currentPlanIndex = index;
        }
      });
      this.modal1 = false;
    },
    startTrain: Debounce(function () {
      if (this.trainDetails.trainBtnStatus === 2) {
        return;
      }
      if (this.trainDetails.trainBtnStatus === 0) {
        let data;
        this.$http
          .post(
            "patient/train/today",
            this.$http.adornData(data, false, "json"),
            this.$http.jsonContent()
          )
          .then((res) => {
            console.log(res);
            if (res.code === 1) {
              // todo
              // data = 1 时今日已训练 0时今日未训练
              if (res.data === 0) {
                this.modal2 = true;
              }
              if (res.data === 1) {
                // 点击【开始训练】 全屏显示
                // const element = document.getElementsByTagName("body")[0];
                // // let element = document.documentElement;
                // if (element.requestFullscreen) {
                //   element.requestFullscreen();
                // } else if (element.webkitRequestFullScreen) {
                //   element.webkitRequestFullScreen();
                // } else if (element.mozRequestFullScreen) {
                //   element.mozRequestFullScreen();
                // } else if (element.msRequestFullscreen) {
                //   // IE11
                //   element.msRequestFullscreen();
                // }
                // element.requestFullscreen(); // todo
                // console.log("body", element);
                let patientPlanId =
                  this.todayTrainInfo.planList[this.currentPlanIndex]
                    .patientPlanId;
                let againTrainFlg = this.againTrainFlg;
                console.log("againTrainFlg", againTrainFlg);
                console.log("patientPlanId", patientPlanId);
                // 跳转到详情页
                // this.$router.push('/main/traininfo')
                // 动态路由 携带参数
                this.$router.push({
                  path: "/main/traininfo",
                  query: {
                    patientPlanId: patientPlanId,
                    againTrainFlg: againTrainFlg,
                    currentPlanIndex: this.currentPlanIndex,
                    imagedata: this.imagedata,
                  },
                });
              }
            } else {
              this.$Message.error(res.msg);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (this.trainDetails.trainBtnStatus === 1) {
        // 点击【开始训练】 全屏显示
        // const element = document.getElementsByTagName("body")[0];
        // // let element = document.documentElement;
        // if (element.requestFullscreen) {
        //   element.requestFullscreen();
        // } else if (element.webkitRequestFullScreen) {
        //   element.webkitRequestFullScreen();
        // } else if (element.mozRequestFullScreen) {
        //   element.mozRequestFullScreen();
        // } else if (element.msRequestFullscreen) {
        //   // IE11
        //   element.msRequestFullscreen();
        // }
        // element.requestFullscreen(); // todo
        // console.log("body", element);
        let patientPlanId =
          this.todayTrainInfo.planList[this.currentPlanIndex].patientPlanId;
        let againTrainFlg = this.againTrainFlg;
        console.log("againTrainFlg", againTrainFlg);
        console.log("patientPlanId", patientPlanId);
        // 跳转到详情页
        // this.$router.push('/main/traininfo')
        // 动态路由 携带参数
        this.$router.push({
          path: "/main/traininfo",
          query: {
            patientPlanId: patientPlanId,
            againTrainFlg: againTrainFlg,
            currentPlanIndex: this.currentPlanIndex,
            imagedata: this.imagedata,
          },
        });
      }
    }, 1500),
    closesModal2: Debounce(function () {
      this.modal2 = false;
      // 点击【开始训练】 全屏显示
      // const element = document.getElementsByTagName("body")[0];
      // // let element = document.documentElement;
      // if (element.requestFullscreen) {
      //   element.requestFullscreen();
      // } else if (element.webkitRequestFullScreen) {
      //   element.webkitRequestFullScreen();
      // } else if (element.mozRequestFullScreen) {
      //   element.mozRequestFullScreen();
      // } else if (element.msRequestFullscreen) {
      //   // IE11
      //   element.msRequestFullscreen();
      // }
      // element.requestFullscreen(); // todo
      // console.log("body", element);
      let patientPlanId =
        this.todayTrainInfo.planList[this.currentPlanIndex].patientPlanId;
      let againTrainFlg = this.againTrainFlg;
      console.log("againTrainFlg", againTrainFlg);
      console.log("patientPlanId", patientPlanId);
      // 跳转到详情页
      // this.$router.push('/main/traininfo')
      // 动态路由 携带参数
      this.$router.push({
        path: "/main/traininfo",
        query: {
          patientPlanId: patientPlanId,
          againTrainFlg: againTrainFlg,
          currentPlanIndex: this.currentPlanIndex,
          imagedata: this.imagedata,
        },
      });
    }, 1500),
  },
};
</script>
<style lang="scss" scoped>
@import "@css/mixin.scss";
.colors {
  color: #4174f7 !important;
}
.container {
  width: 100%;
  height: 100%;
  overflow: auto;
  .haveData {
    padding: 40px 80px;
    .top {
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      padding: 32px 24px 32px 24px;
      display: flex;
      .left {
        width: 1106px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 60px;
        border-right: 1px solid #ededed;

        .center_left_top_left {
          min-width: 240px;
          display: flex;
          align-items: center;
          img {
            width: 52px;
            height: 52px;
            border-radius: 100%;
          }
          span {
            margin: 0 10px;
            font-size: 20px;
            font-weight: 400;
            color: #666666;
          }
          span:nth-child(1) {
            font-weight: bolder;
            color: #333;
          }
        }
        h1 {
          min-width: 240px;
          margin-left: 40px;
          font-size: 20px;
          color: #333333;
        }
        .center_left_top_right {
          min-width: 240px;
          span {
            font-size: 20px;
            font-weight: bolder;
            color: #333333;
          }
        }
      }
      // .right {
      //   margin-left: 14px;
      //   padding-left: 60px;
      //   display: flex;
      //   align-items: center;
      //   p:nth-child(1) {
      //     margin-right: 14px;
      //     font-size: 24px;
      //     font-weight: bolder;
      //     color: #333333;
      //     white-space: nowrap;
      //   }
      //   p:nth-child(2) {
      //     display: flex;
      //     flex-wrap: wrap;
      //     span {
      //       min-width: 160px;
      //       max-width: 184px;
      //       padding: 12px 0;
      //       box-sizing: border-box;
      //       display: flex;
      //       align-items: center;
      //       justify-content: center;
      //       white-space: nowrap;
      //       border-radius: 24px;
      //       border: 1px solid #999;
      //       margin-right: 24px;
      //     }
      //     span:last-child {
      //       margin-right: 0;
      //     }
      //   }
      // }
      .train-plan {
        width: 718px;
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
        justify-content: center;
        .info-box {
          margin-top: unset;
          .name {
            font-size: 24px;
            font-weight: bolder;
            color: #333333;
          }
        }
        .icon {
          // width: 22px;
          // background: url("~@/assets/img/patientend/i-plan.png");
          // background-position: center;
          // background-size: cover;
          @include setIcon(url("~@/assets/img/patientend/i-plan.png"), 22px);
        }
        .plan-box {
          display: flex;
          flex-direction: row;
          margin-left: 19px;
          .current {
            position: relative;
            box-sizing: border-box;
            .content {
              padding: 0 32px;
              width: 284px;
              height: 48px;
              cursor: pointer;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              background: #4174f7;
              border-radius: 24px;
              font-weight: 400;
              line-height: 36px;
              font-size: 24px;
              font-weight: bolder;
              color: #ffffff;
              box-sizing: border-box;
            }
            img {
              width: 17px;
              height: 16px;
              margin-left: 10px;
            }
            ul.plan-list {
              position: absolute;
              top: 50px;
              left: 10px;
              width: 284px;
              max-height: 400px;
              padding: 29px;
              background: #ffffff;
              box-shadow: 0px 0px 38px 1px rgba(35, 97, 152, 0.15);
              z-index: 99;
              overflow: auto;
              border-radius: 12px;
              li {
                margin-bottom: 26px;
                cursor: pointer;
                user-select: none;
                &:last-child {
                  margin-bottom: 0;
                }
                img {
                  width: 18px;
                  height: 18px;
                  margin-right: 12px;
                }
                span {
                  font-weight: 300;
                  opacity: 0.7;
                  font-size: 24px;
                  font-weight: 400;
                  color: #333333;
                }
                span.active {
                  color: #4174f7;
                  opacity: 1;
                }
              }
            }
            .plan-list::-webkit-scrollbar {
              /*滚动条整体样式*/
              width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
              height: 1px;
            }
            .plan-list::-webkit-scrollbar-thumb {
              /*滚动条里面小方块*/
              border-radius: 10px;
              box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
              background: #666;
            }
            .plan-list::-webkit-scrollbar-track {
              /*滚动条里面轨道*/
              box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
              border-radius: 10px;
              background: #ededed;
            }
          }
        }
        .more {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 98px;
          height: 48px;
          margin-left: 19px;
          background: #e7f2fd;
          border-radius: 24px;
          cursor: pointer;
          span {
            color: #4174f7;
            font-size: 20px;
          }
          .icon-blue {
            background: url("~@/assets/img/patientend/i-more.png") no-repeat;
          }
          .icon-white {
            background: url("~@/assets/img/patientend/i-nomore.png") no-repeat;
          }
          // .icon-close {
          //     font-size: 20px;
          //     color: #4174F7;
          // }
          .icon {
            display: inline-block;
            height: 6px;
            width: 30px;
            margin-right: unset;
            background-position: center;
            background-size: contain;
          }
        }
      }
    }
    .center {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      .center_left {
        width: 868px;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        .center_left_top {
          display: flex;
          justify-content: space-between;
          padding: 24px;
          margin-bottom: 20px;
          .center_left_top_left {
            width: 100%;
            display: flex;
            justify-content: space-between;
            span:nth-child(1) {
              font-size: 24px;
              font-weight: bolder;
              color: #333333;
            }
            span:nth-child(2) {
              margin-left: 24px;
              font-size: 20px;
              font-weight: 400;
              color: #666666;
            }
          }
        }
        .center_left_content {
          padding: 0 28px 40px 38px;
          display: flex;
          align-items: center;
          h1 {
            font-size: 20px;
            font-weight: 400;
            color: #666666;
          }
          .center_left_content_courss {
            margin-left: 8px;
            display: flex;
            p {
              width: 496px;
              height: 18px;
              background: rgba(65, 116, 247, 0.25);
              border-radius: 10px;
              margin-right: 8px;
              display: flex;
            }
            span {
              height: 18px;
              background: rgba(65, 116, 247, 0.7);
              border-radius: 12px 0px 0px 12px;
              display: block;
              text-align: right;
              position: relative;
              img {
                width: 16px;
                height: 42px;
                margin-top: -24px;
                margin-right: -16px;
              }
              h1 {
                font-size: 20px;
                font-weight: 400;
                color: #4174f7;
                position: absolute;
                display: block;
                white-space: nowrap;
                bottom: -34px;
                right: -25px;
              }
            }

            font-size: 20px;
            font-weight: 400;
            color: #666666;
          }
          .center_left_content_img {
            width: 64px;
            height: 64px;
            background: url("../assets/img/trophy.png") no-repeat;
            background-size: 100% 100%;
            margin-left: 37px;
            padding-top: 10px;
            text-align: center;
            span {
              font-size: 12px;
              font-weight: 400;
              color: #ffffff;
            }
            span:nth-child(1) {
              font-weight: bolder;
              font-size: 18px;
            }
          }
        }
      }
      .center_right {
        width: 868px;
        background: #fff;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
        border-radius: 12px;
        .center_right_top {
          display: flex;
          justify-content: space-between;
          padding: 24px;
          margin-bottom: 20px;
          .center_right_top_left {
            span:nth-child(1) {
              font-size: 24px;
              font-weight: bolder;
              color: #333333;
              margin-right: 24px;
            }
            span:nth-child(2) {
              font-size: 20px;
              font-weight: 400;
              color: #666666;
            }
          }
          .center_right_top_right {
            font-size: 20px;
            font-weight: 400;
            color: #4174f7;
          }
        }
        .center_right_content {
          display: flex;
          align-items: center;
          padding: 0 35px;
          p {
            width: 175px;
            height: 9px;
            background: #dee7ff;
            span {
              display: block;
              height: 9px;
              background: #7197f9;
            }
          }
          p:nth-child(1) {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            span {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }
          }
          img {
            margin: 0 5px;
            width: 30px;
            height: 30px;
          }
        }
        .center_right_content1 {
          display: flex;
          align-items: center;
          justify-content: center;
          // margin-top: 46px;
          p {
            font-size: 18px;
            font-weight: 400;
            color: #666666;
          }
        }
        .center_right_bottom {
          padding: 0 83px 0 70px;
          margin-top: 18px;
          margin-bottom: 22px;
          display: flex;
          justify-content: space-between;
          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            p:nth-child(1) {
              font-size: 20px;
              font-weight: 400;
              color: #666666;
            }
            p:nth-child(2) {
              margin-top: 8px;
              font-size: 18px;
              font-weight: 400;
              color: #b2b2b2;
            }
          }
        }
      }
    }
    .bottom {
      margin-top: 24px;
      background: #ffffff;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
      border-radius: 12px;
      padding-bottom: 52px;
      .bottom_top {
        padding: 24px;
        border-bottom: 1px solid #ededed;
        display: flex;
        align-items: center;
        p:nth-child(1) {
          font-size: 24px;
          font-weight: bolder;
          color: #333333;
        }
        p:nth-child(2) {
          margin-left: 24px;
          font-size: 20px;
          font-weight: 400;
          color: #666666;
        }
      }
      .bottom_content {
        padding: 52px 0 52px 248px;
        display: flex;
        .bottom_content_bigbox {
          display: flex;
          align-items: center;
          margin-right: 31px;
          .bottom_content_box {
            background: url("../assets/img/xunlian.png") no-repeat;
            background-size: 100% 100%;
            width: 268px;
            cursor: pointer;
            span {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 24px 64px 24px 52px;
              border-bottom: rgba(255, 255, 255, 0.15) 2px solid;
              white-space: nowrap;
              font-size: 20px;
              font-weight: bolder;
              color: #fff;
            }
            div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 32px 12px 32px 0;
              img {
                width: 86px;
                height: 86px;
                opacity: 0.9;
                border-radius: 50%;
                border: 6px solid #fff;
              }
              p {
                font-size: 20px;
                font-weight: 400;
                color: #fff;
                margin-top: 10px;
              }
            }
          }
          .zhixiang {
            width: 18px;
            height: 18px;
            margin-left: 31px;
          }
        }
        .bottom_content_bigbox:first-child {
          .bottom_content_box {
            background: url("../assets/img/yubeike.png") no-repeat;
            background-size: 100% 100%;
            width: 256px;
            span {
              padding: 24px 63px;
              border-bottom: none;
            }
            div {
              padding: 32px 0;
            }
          }
        }
        .bottom_content_bigbox:last-child {
          margin-right: 0;
          .zhixiang {
            display: none;
          }
        }
      }
      .btn {
        display: flex;
        justify-content: center;
        span {
          background: #4174f7;
          box-shadow: 0px 2px 4px 0px rgba(65, 116, 247, 0.3);
          border-radius: 8px;
          padding: 15px 52px;
          color: #fff;
          cursor: pointer;
          font-size: 24px;
          font-weight: bolder;
        }
      }
    }
    .textShow {
      background: none !important;
      box-shadow: none !important;
      border-radius: 0 !important;
      padding: 0 !important;
      cursor: initial !important;
      font-size: 20px !important;
      font-weight: 400 !important;
      color: #666666 !important;
    }
  }
}
.nohaveData {
  padding: 40px 80px;
  .top {
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.07);
    border-radius: 12px;
    .top_up {
      display: flex;
      align-items: center;
      padding: 20px 0;
      margin: 0 24px;
      border-bottom: 1px solid #ededed;
      img {
        width: 52px;
        height: 52px;
        border-radius: 100%;
      }
      span {
        margin: 0 10px;
        font-size: 20px;
        font-weight: 400;
        color: #666666;
      }
      span:nth-child(1) {
        font-weight: bolder;
        color: #333;
      }
      h1 {
        margin-left: 40px;
        font-size: 20px;
        color: #333333;
      }
    }
    .top_content {
      height: 540px;
      .top_content_box {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 132px;
          height: 110px;
        }
        p {
          font-size: 20px;
          font-weight: 400;
          color: #666666;
          margin-top: 18px;
        }
      }
    }
  }
}
// .middle {
//   position: relative;
//   width: 100%;
//   height: 300px;
//   margin-top: 36px;
//   .chart-box {
//     position: absolute;
//     left: 50%;
//     top: 55%;
//     transform: translate(-50%, -66%);
//     // width: 600px;
//     // height: 350px;
//     background: transparent;
//   }
//   .icon-box {
//     position: absolute;
//     bottom: 30px;
//     left: 50%;
//     transform: translateX(-40%);
//     display: flex;
//     flex-direction: row;
//     z-index: 999;
//     .icon-1 {
//       display: flex;
//       flex-direction: row;
//       align-items: center;
//       margin-right: 20px;
//       font-size: 18px;
//       .icon {
//         width: 20px;
//         height: 20px;
//         margin-right: 5px;
//       }
//     }
//   }
//   .c1 {
//     background: #fcf1ce;
//   }
//   .c2 {
//     background: #def5ec;
//   }
//   .c3 {
//     background: #d0e7f9;
//   }
// }
/deep/ .modal1 {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    width: 552px !important;
    top: 0 !important;
    .ivu-modal-content {
      .ivu-modal-body {
        padding: 56px 60px !important;
        .patientName {
          font-size: 24px;
          font-weight: bolder;
          color: #333333;
        }
        .bth {
          margin-top: 58px;
          display: flex;
          justify-content: center;
          button {
            min-width: 200px;
            padding: 15px 0;
            box-sizing: border-box;
            border: 1px solid #4174f7;
            border-radius: 9px;
            background: #fff;
            font-size: 24px;
            font-weight: bolder;
            color: #4174f7;
            cursor: pointer;
          }
          button:nth-child(1) {
            margin-right: 32px;
          }
          button:nth-child(2) {
            background: #4174f7;
            color: #fff;
          }
        }
      }
    }
  }
}
/deep/ .modal2 {
  display: flex;
  align-items: center;
  justify-content: center;
  .ivu-modal {
    top: 0;
    width: 1274px !important;
    border-radius: 20px 20px 0px 0px !important;
    .ivu-modal-content {
      .ivu-modal-close {
        margin-right: 36px;
        .ivu-icon {
          color: #333;
          font-weight: bolder;
          font-size: 60px;
        }
      }
      .ivu-modal-header {
        background: #e5f2fc;
        border-radius: 20px 20px 0px 0px;
        height: 85px;
        padding: 0;
        .ivu-modal-header-inner {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 30px;
          font-weight: 400;
          color: #333333;
          height: 85px;
          line-height: 100%;
        }
      }
      .ivu-modal-body {
        padding: 36px 80px;
        div {
          font-size: 24px;
          font-weight: 400;
          color: #333333;
          margin-bottom: 36px;
        }
        div:nth-child(1) {
          span {
            color: #4174f7;
          }
        }
        .bth {
          margin-bottom: 0;
          margin-top: 72px;
          display: flex;
          justify-content: center;
          .but1 {
            background: #4174f7;
            border-radius: 9px;
            font-size: 24px;
            font-weight: bolder;
            color: #ffffff;
            border: none;
            padding: 15px 26px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
// .container {
//   position: relative;
//   height: 100%;
//   background-color: #fafafa;
//   .empty-show {
//     position: absolute;
//     top: 120px;
//     left: 50%;
//     transform: translateX(-50%);
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     width: 1482px;
//     height: 633px;
//     background: url("~@/a
// ssets/img/patientend/empty-bg.png");
//     background-size: cover;
//     .title {
//       margin-top: 91px;
//       font-size: 36px;
//       font-weight: 500;
//       color: #fe7a00;
//     }
//     .tip {
//       margin-top: 17px;
//       font-size: 24px;
//       font-weight: 400;
//       color: #000000;
//       opacity: 0.7;
//     }
//     img {
//       margin-top: 3px;
//       width: 471px;
//       height: 291px;
//     }
//   }
//   .frame-box {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     width: 1400px;
//     height: 800px;
//     z-index: 999;
//     iframe {
//       width: 100%;
//       height: 100%;
//     }
//   }
//   .video-box {
//     width: 1400px;
//     height: 800px;
//     z-index: 999;
//   }
//   .card {
//     position: absolute;
//     top: 40px;
//     left: 80px;
//     width: 1760px;
//     // height: 794px;
//     height: 770px; // 为了显示底部产品信息
//     background: #fff;
//     border: 1px solid #d2d2d2;
//     box-shadow: 1px 6px 38px 1px rgba(35, 97, 152, 0.15);
//     border-radius: 40px;
//     line-height: 1;
//     .ivu-spin {
//       border-radius: 40px 40px 0 0;
//     }
//     // 小背景图标
//     .icon {
//       // display: inline-block;
//       width: 24px;
//       height: 24px;
//       margin-right: 12px;
//     }
//     .name {
//       font-size: 20px;
//       color: rgba(102, 102, 102, 1);
//       font-weight: 400;
//     }
//     .val {
//       font-size: 28px;
//       color: #000;
//       font-weight: 500;
//     }
//     .head {
//       display: flex;
//       flex-direction: row;
//       // align-items: center;
//       position: relative;
//       height: 108px;
//       padding-left: 50px;
//       border-radius: 40px 40px 0 0;
//       background-color: #fff;
//       border-bottom: 1px solid #d2d2d2;
//       line-height: unset;
//       // 解决底部对齐
//       .info-box {
//         display: flex;
//         flex-direction: row;
//         align-items: flex-end;
//         margin-top: 37px;
//         // planList为空时--的样式
//         .empty-val {
//           margin-left: 10px;
//           font-size: 28px;
//           line-height: 25px;
//           color: #000;
//           font-weight: 500;
//         }
//       }
//       .patient {
//         position: relative;
//         .icon {
//           @include setIcon(url("~@/assets/img/patientend/i-user.png"), 20px);
//         }
//         padding-right: 30px;
//         &:after {
//           content: "";
//           display: block;
//           position: absolute;
//           top: 50%;
//           right: 0;
//           transform: translateY(-50%);
//           width: 1px;
//           height: 18px;
//           background: #d2d2d2;
//         }
//         //  border-right: 1px solid blue;
//         .gender,
//         .age {
//           margin-left: 12px;
//           font-size: 20px;
//           color: #000;
//           font-weight: 500;
//         }
//         /deep/ .ivu-poptip-content {
//           font-size: 24px;
//           font-weight: 500;
//         }
//       }
//       .brain-rate {
//         position: relative;
//         padding: 0 30px;
//         //  border-right: 1px solid blue;
//         .icon {
//           // background: url("~@/assets/img/patientend/i-data.png");
//           // background-position: center;
//           // background-size: cover;
//           @include setIcon(url("~@/assets/img/patientend/i-data.png"), 24px);
//         }
//         .val {
//           line-height: 22px;
//         }
//         .all {
//           font-size: 14px;
//           font-weight: 500;
//           color: #4174F7;
//         }
//         .icon-line {
//           width: 8px;
//           height: 19px;
//           margin: 0 4px;
//           background: url("~@/assets/img/patientend/traininfo-num.png");
//           background-size: cover;
//         }
//         &:after {
//           content: "";
//           display: block;
//           position: absolute;
//           top: 50%;
//           right: 0;
//           transform: translateY(-50%);
//           width: 1px;
//           height: 18px;
//           background: #d2d2d2;
//         }
//       }
//       .train-date {
//         position: relative;
//         padding: 0 30px;
//         //  border-right: 1px solid blue;
//         .icon {
//           // background: url("~@/assets/img/patientend/i-clock.png");
//           // background-position: center;
//           // background-size: cover;
//           @include setIcon(url("~@/assets/img/patientend/i-clock.png"));
//         }
//         .val {
//           line-height: 24px;
//         }
//         &:after {
//           content: "";
//           display: block;
//           position: absolute;
//           top: 50%;
//           right: 0;
//           transform: translateY(-50%);
//           width: 1px;
//           height: 18px;
//           background: #d2d2d2;
//         }
//       }
//       .train-plan {
//         display: flex;
//         flex-direction: row;
//         position: relative;
//         align-items: center;
//         padding-left: 30px;
//         .info-box {
//           margin-top: unset;
//         }
//         .icon {
//           // width: 22px;
//           // background: url("~@/assets/img/patientend/i-plan.png");
//           // background-position: center;
//           // background-size: cover;
//           @include setIcon(url("~@/assets/img/patientend/i-plan.png"), 22px);
//         }
//         .plan-box {
//           display: flex;
//           flex-direction: row;
//           margin-left: 19px;
//           .current {
//             display: flex;
//             flex-direction: row;
//             justify-content: center;
//             align-items: center;
//             width: 234px;
//             height: 48px;
//             background: #4174F7;
//             border-radius: 24px;
//             font-size: 20px;
//             font-weight: 400;
//             line-height: 36px;
//             color: #ffffff;
//           }
//         }
//         .more {
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           width: 98px;
//           height: 48px;
//           margin-left: 19px;
//           background: #e7f2fd;
//           border-radius: 24px;
//           cursor: pointer;
//           span {
//             color: #4174F7;
//             font-size: 20px;
//           }
//           .icon-blue {
//             background: url("~@/assets/img/patientend/i-more.png") no-repeat;
//           }
//           .icon-white {
//             background: url("~@/assets/img/patientend/i-nomore.png") no-repeat;
//           }
//           // .icon-close {
//           //     font-size: 20px;
//           //     color: #4174F7;
//           // }
//           .icon {
//             display: inline-block;
//             height: 6px;
//             width: 30px;
//             margin-right: unset;
//             background-position: center;
//             background-size: contain;
//           }
//         }
//         ul.plan-list {
//           position: absolute;
//           top: 94px;
//           right: 0;
//           width: 248px;
//           //   height: 400px;
//           padding: 29px;
//           background: #ffffff;
//           box-shadow: 0px 0px 38px 1px rgba(35, 97, 152, 0.15);
//           z-index: 99;
//           li {
//             margin-bottom: 22px;
//             cursor: pointer;
//             user-select: none;
//             &:last-child {
//               margin-bottom: 0;
//             }
//             img {
//               width: 18px;
//               height: 18px;
//               margin-right: 12px;
//             }
//             span {
//               font-size: 20px;
//               font-weight: 300;
//               color: #000;
//               opacity: 0.7;
//             }
//             span.active {
//               color: #4174F7;
//               opacity: 1;
//             }
//             // display: flex;
//             // justify-content: center;
//             // align-items: center;
//             // width: 220px;
//             // height: 40px;
//             // margin-bottom: 20px;
//             // background: #e7f2fd;
//             // border: 1px solid #4174F7;
//             // border-radius: 20px;
//             // font-size: 20px;
//             // color: #4174F7;
//             // cursor: pointer;
//             // user-select: none;
//             // &:last-child {
//             //     margin-bottom: 0;
//             // }
//           }
//         }
//       }
//     }
//     .middle {
//       display: flex;
//       flex-direction: row;
//       padding: 30px 160px 0 160px;
//       .plan-count {
//         display: flex;
//         flex-direction: column;
//         // justify-content: center;
//         width: 270px;
//         height: 143px;
//         margin-right: 30px;
//         //  padding-top: 42px;
//         background: #f5f5f5;
//         border-radius: 12px;
//         i.spot {
//           display: inline-block;
//           width: 18px;
//           height: 18px;
//           margin-right: 11px;
//           border-radius: 50%;
//         }
//         .count {
//           margin-right: 12px;
//           font-size: 20px;
//           color: #010101;
//           opacity: 0.7;
//         }
//         .num {
//           font-size: 20px;
//           color: #1982e7;
//         }
//         .all {
//           margin-left: 40px;
//           margin-top: 40px;
//           // margin-bottom: 10px;
//           .spot {
//             background: #5cd4e2;
//           }
//         }
//         .left {
//           margin-left: 40px;
//           margin-top: 24px;
//           .spot {
//             background: #f6cb45;
//           }
//         }
//       }
//       .desc {
//         width: 880px;
//         height: 143px;
//         margin-right: 79px;
//         padding: 30px 42px 34px 40px;
//         background: #f5f5f5;
//         border-radius: 12px;
//         font-size: 20px;
//         color: #000;
//         line-height: 36px;
//       }
//       .diagram {
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//         align-items: center;
//         .chart-box {
//           position: relative;
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           width: 105px;
//           height: 105px;
//           // padding: 4px;
//           // background: #f5f5f5;
//           // border-radius: 52px;
//           border-radius: 50%;
//           padding: 5px;
//           background: rgba(35, 97, 152, 0.15);
//           // box-shadow: 1px 6px 38px 1px
//         }
//         span {
//           font-size: 20px;
//           font-weight: 400;
//           color: #010101;
//           opacity: 0.7;
//         }
//         .ab-percent {
//           position: absolute;
//           top: 50%;
//           left: 50%;
//           transform: translate(-50%, -50%);
//           font-weight: 700;
//           .num {
//             font-size: 30px;
//             color: #fff;
//             font-weight: 600;
//           }
//           .percent {
//             font-size: 18px;
//             color: #fff;
//             font-weight: 400;
//           }
//         }
//       }
//     }
//     .bottom-box {
//       display: flex;
//       flex-direction: row;
//       justify-content: center;
//       margin-top: 30px;
//       .bottom {
//         width: 1440px;
//         height: 422px;
//         background: url("~@/assets/img/patientend/bluebottom.png");
//         //  background-position: center;
//         background-size: cover;
//         .lesson-list {
//           display: flex;
//           flex-direction: row;
//           justify-content: space-between;
//           padding: 40px 210px 0 210px;
//           .lesson {
//             width: 210px;
//             height: 230px;
//             border-radius: 30px;
//             background-color: #fff;
//             box-shadow: 1px 6px 38px 1px rgba(35, 97, 152, 0.15);
//             cursor: pointer;
//             .hd {
//               display: flex;
//               justify-content: center;
//               align-items: center;
//               height: 40px;
//               border-radius: 30px 30px 0 0;
//               background: #8cc0f3;
//               span {
//                 font-size: 20px;
//                 color: #ffffff;
//               }
//             }
//             .bt {
//               display: flex;
//               flex-direction: column;
//               //  justify-content: center;
//               align-items: center;
//               .pic {
//                 width: 110px;
//                 height: 110px;
//                 margin-top: 20px;
//                 border-radius: 50%;
//                 border: 5px solid #ffffff;
//                 box-shadow: 0px 0px 18px 0px rgba(141, 173, 201, 0.36);
//                 text-align: center;
//                 img {
//                   width: 100%;
//                   height: 100%;
//                 }
//               }
//               p {
//                 margin-top: 13px;
//                 font-size: 24px;
//                 color: #010101;
//               }
//             }
//           }
//         }
//       }
//       .btn-box {
//         position: relative;
//         display: flex;
//         justify-content: center;
//         margin-top: 24px;
//         .clickuse {
//           max-width: 314px;
//           cursor: pointer;
//         }
//         img {
//           width: 314px;
//           height: 90px;
//         }
//         span {
//           position: absolute;
//           top: 50%;
//           left: 50%;
//           transform: translate(-50%, -60%);
//           font-size: 36px;
//           font-weight: 500;
//           color: #ffffff;
//           z-index: 999;
//         }
//       }
//     }
//   }
// }
</style>
