<template>
    <!--修改密码-->
    <Modal
        v-model="RestPassShow"
        title="修改密码"
        class-name="vertical-center-modal"
        :footer-hide="true"
        @on-cancel="cancel('formCustom')"
        width="700">
        <Form  ref="formCustom" :model="formCustom" :rules="ruleCustom" :label-width="130">
            <Row>
                <i-col span="20" offset="2">
                    <FormItem label="登录账号：" required prop="phone">
                        <Row>
                            <i-col span="14">
                                <i-input v-model="formCustom.phone" placeholder="请输入账号" size="large" readonly></i-input>
                            </i-col>
                            <i-col span="8" >
                                <Button type="primary" size="large" @click="getvcCode" v-if="codeShow">获取验证码</Button>
                                <Button type="warning" size="large" v-else>倒计时{{miao}}S</Button>
                            </i-col>
                        </Row>
                    </FormItem>
                </i-col>
            </Row>
            <Row>
                <i-col span="20" offset="2">
                    <FormItem label="验证码：" required prop="vcCode">
                        <i-input v-model="formCustom.vcCode" placeholder="请输入验证码" size="large"></i-input>
                    </FormItem>
                </i-col>
            </Row>
            <Row>
                <i-col span="20" offset="2">
                    <FormItem label="设置新密码：" required prop="newPassword">
                        <i-input v-model="formCustom.newPassword" placeholder="请输入密码" size="large" type="password"></i-input>
                    </FormItem>
                </i-col>
            </Row>
            <Row>
                <i-col span="20" offset="2">
                    <FormItem label="确认新密码：" required prop="passwdCheck">
                        <i-input v-model="formCustom.passwdCheck" placeholder="请再次输入密码" size="large" type="password"></i-input>
                    </FormItem>
                </i-col>
            </Row>
            <FormItem class="bth">
                <Button type="primary" size="large" @click="handleSubmit('formCustom')">保存</Button>
            </FormItem>
        </Form>
    </Modal>
</template>

<script>
    import { getCookieDphone } from '@js/session.sg'
    export default {
        data () {
            const validateuphone = (rule, value, callback) => {
                if (value === '') {
                    return callback(new Error('请输入账号'));
                } else {
                    if (value.length !== 11) {
                        return callback(new Error('账号格式错误'));
                    }
                    callback();
                }
            };
            const validateuvcCode = (rule, value, callback) => {
                if (value === '') {
                    return callback(new Error('请输入验证码'));
                } else {
                    callback();
                }
            };
            const validatePass = (rule, value, callback) => {
                if (value === '') {
                    return callback(new Error('请输入密码'));
                } else {
                    callback();
                }
            };
            const validatePassCheck = (rule, value, callback) => {
                if (value === '') {
                    return callback(new Error('请再次输入密码'));
                } else if (value !== this.formCustom.newPassword) {
                    callback(new Error('两次密码输入不一致'));
                } else {
                    callback();
                }
            };
            return {
                codeShow: true, // 获取验证码是否可以点击
                miao: 59,
                IntervalID: '',
                // 忘记密码
                formCustom: {
                    phone: getCookieDphone(),
                    vcCode: '',
                    newPassword: '',
                    passwdCheck: '',
                    vcToken: ''
                },
                ruleCustom: {
                    phone: [
                        { required: true, validator: validateuphone, trigger: 'blur' }
                    ],
                    vcCode: [
                        { required: true, validator: validateuvcCode, trigger: 'blur' }
                    ],
                    newPassword: [
                        { required: true, validator: validatePass, trigger: 'blur' }
                    ],
                    passwdCheck: [
                        { required: true, validator: validatePassCheck, trigger: 'blur' }
                    ],

                },
                RestPassShow: false,
            }
        },
        methods: {
            // 点击修改密码 弹框  获取验证码
            getvcCode () {
                if (this.formCustom.phone === '') {
                    this.$Message.warning('请输入手机号');
                } else {
                    clearInterval(this.IntervalID);
                    this.codeShow = false;
                    this.countDown()
                    let data = {
                        phone: this.formCustom.phone,
                    };
                    this.$http.post('notify/sms/sendVcCode', this.$http.adornData(data, false, 'form'))
                        .then((res) => {
                            console.log(res);
                            if (res.code === 1) {
                                this.$Message.success('验证码已发送，请注意查收');
                                this.formCustom.vcToken = res.data.vcToken
                            } else {
                                clearInterval(this.IntervalID);
                                this.codeShow = true;
                                this.miao = 59;
                                this.$Message.error(res.msg);
                            }
                        }).catch(err => {
                        console.log(err);
                    });
                }
            },
            // 倒计时 60秒
            countDown () {
                let that = this;
                this.IntervalID = setInterval(function () {
                    that.miao--;
                    if (that.miao < 1) {
                        clearInterval(that.IntervalID);
                        that.miao = 59;
                        that.codeShow = true;
                    }
                }, 1000)
            },
            // 点击修改密码  弹框 确定按钮
            handleSubmit (name) {
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        let data  = this.formCustom
                        this.$http.post('doctor/info/resetPwd', this.$http.adornData(data, false, 'form'))
                            .then((res) => {
                                console.log(res);
                                if (res.code === 1) {
                                    // todo
                                    this.$Message.success(res.data);
                                    this.RestPassShow = false;
                                    this.$store.dispatch('setToken', '');
                                    clearInterval(this.IntervalID);
                                    this.$store.dispatch('setDocInfo', '');
                                    this.codeShow = true;
                                    this.miao = 59;
                                    this.$router.push('/main/');
                                } else {
                                    clearInterval(this.IntervalID);
                                    this.codeShow = true;
                                    this.miao = 59;
                                    this.$Message.error(res.msg);
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    } else {
                        console.log('fail')
                    }
                })
            },
            // 点击关闭忘记密码弹框
            cancel (name) {
                clearInterval(this.IntervalID);
                this.codeShow = true;
                this.miao = 59;
                this.$refs[name].resetFields();
            },
            // 点击打开忘记密码弹框
            openUpdaPassMod () {
                this.RestPassShow = true;
            }
        }
    }
</script>

<style scoped lang='scss'>
    /*upda-pass-mod*/
    // modal 弹出框垂直水平居中
    /deep/.vertical-center-modal{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    /deep/.vertical-center-modal .ivu-modal{
        top: 0;
    }
    /deep/.vertical-center-modal .ivu-modal-header-inner{
        text-align: center;
    }
    /deep/.ivu-modal{
        .ivu-modal-body{
            padding: 30px;
            .bth{
                text-align: center;
                .ivu-form-item-content{
                    margin-left: 0 !important;
                }
            }
        }
        /deep/.ivu-modal-header{
            padding: 20px 30px;
            .ivu-modal-header-inner{
                font-size: 24px;
                height: 24px;
                line-height: 24px;
            }
        }
    }
    /deep/.ivu-form-item-label{
        font-size: 16px !important;
    }
</style>
